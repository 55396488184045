.app-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100vh;
  background-color: #000512;
}

.text-container {
  width: 80%;
  text-align: center;
  position: absolute;
  bottom: 100px;
  font-family: 'Montserrat Alternates', sans-serif;
  font-size: 30px;
  color: white;
  padding: 20px;
  overflow: hidden;
  transition: opacity 0.5s ease-in-out;
  user-select: none;
}

.text-container img {
  cursor: pointer;
}

.button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  position: absolute;
  bottom: 20px;
  color: #fff;
  font-family: 'Montserrat Alternates', sans-serif;
}

.phase-text {
  cursor: pointer;
}

.video-container {
  width: 650px;
  position: relative;
}

.video-player {
  width: 100%;
  border-radius: 8px;
}

@media (min-width: 600px) and (max-width: 1023px) {
  .text-container {
    width: 70%; 
    bottom: 100px;
    font-size: 26px;
  }

  .video-container {
    width: 80%;
  }
}

@media (min-width: 1024px) {
  .text-container {
    width: 60%;
    bottom: 120px;
    font-size: 30px;
  }

  .video-container {
    width: 650px;
  }
}

@media (min-width: 600px) and (max-width: 767px) {
  .text-container {
    width: 80%;
    bottom: 80px;
    font-size: 22px;
  }

  .video-container {
    width: 90%;
  }
}

@media (min-width: 100px) and (max-width: 600px) {
  .text-container {
    width: 95%;
    font-size: 16px;
    padding: 8px;
    bottom: 15%;
    font-size: 22px;
  }

  .video-container {
    padding-top: 40%;
    width: 100%;
  }
}
